
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DatePicker, TimePicker, Form, Radio } from 'ant-design-vue';
import { getDatepickerLocale } from '@/helpers/LocaleHelper';
import Rrule from '@/models/interfaces/Rrule';
import moment from 'moment';
import i18n from '@/i18n';
@Component({
    name: 'RecurrenceRule',
    components: {
        Form,
        FormItem: Form.Item,
        Radio,
        RadioGroup: Radio.Group,
        DatePicker,
        TimePicker,
    },
})
export default class RecurrenceRule extends Vue {
    @Prop({ required: true }) private rrule!: Rrule;
    @Prop({ default: 'HH:mm' }) private dateInputFormat!: string;
    @Prop({ default: 'DD. MM. YYYY' }) private dateDisplayFormat!: string;
    private show: boolean = false;
    private frequencyOptions: string[] = [];
    private moment = moment;
    private rruleString: string | null = null;
    private freqToShow: {
        [index: string]: {
            until: boolean;
            interval: boolean;
            month: boolean;
            day_of_month: boolean;
            day_of_the_week: boolean;
            label: string;
        };
    } = {
        yearly: {
            until: true,
            interval: true,
            month: true,
            day_of_month: true,
            day_of_the_week: false,
            label: i18n.t('yearly'),
        },
        monthly: {
            until: true,
            interval: true,
            month: false,
            day_of_month: true,
            day_of_the_week: false,
            label: i18n.t('monthly'),
        },
        weekly: {
            until: true,
            interval: true,
            month: false,
            day_of_month: false,
            day_of_the_week: true,
            label: i18n.t('weekly'),
        },
        daily: {
            until: true,
            interval: true,
            month: false,
            day_of_month: false,
            day_of_the_week: false,
            label: i18n.t('daily'),
        },
        never: {
            until: false,
            interval: false,
            month: false,
            day_of_month: false,
            day_of_the_week: false,
            label: i18n.t('never'),
        },
    };

    private weekdays = [
        { label: i18n.t('Monday'), value: 'MO' },
        { label: i18n.t('Tuesday'), value: 'TU' },
        { label: i18n.t('Wednesday'), value: 'WE' },
        { label: i18n.t('Thursday'), value: 'TH' },
        { label: i18n.t('Friday'), value: 'FR' },
        { label: i18n.t('Saturday'), value: 'SA' },
        { label: i18n.t('Sunday'), value: 'SU' },
    ];
    private months: string[] = [];
    private monthDays: any = {};
    private untilSelected = '4';
    private untilNum: number | null = 999999;
    private interval = 1;

    private get locale() {
        return getDatepickerLocale();
    }

    private intervalFormatter(value: string) {
        const min = 0;
        const max = 365;
        const time = parseInt(value, 10);
        if (isNaN(time)) {
            return `${min}`;
        }
        if (time > max) {
            return `${max}`;
        } else if (time < min) {
            return `${min}`;
        } else {
            return value;
        }
    }
    private untilNumChanged(value: number | string | null) {
        if (value === null) {
            this.rrule.untilNum = value;
            return;
        }
        this.rrule.untilNum = value.toString();
    }
    private intervalChanged(value: number | string) {
        if (value === null) {
            return;
        }
        this.rrule.interval = value.toString();
    }
    private updateStartDate(value: string) {
        const newDate = moment(value, this.dateDisplayFormat);
        const rruleStartDate = moment(this.rrule.startDateTime);
        const rruleEndDate = moment(this.rrule.endDateTime);
        rruleStartDate
            .set('years', newDate.get('years'))
            .set('months', newDate.get('months'))
            .set('date', newDate.get('date'));
        rruleEndDate
            .set('years', newDate.get('years'))
            .set('months', newDate.get('months'))
            .set('date', newDate.get('date'));
        Vue.set(this.rrule, 'startDateTime', rruleStartDate);
        Vue.set(this.rrule, 'endDateTime', rruleEndDate);
    }

    private createIcalEventString({
        frequency,
        interval,
        startDateTime,
        endDateTime,
        daysOfTheMonth,
        daysOfTheWeek,
        untilDate,
        untilNum,
        month,
    }: Rrule) {
        let rrule = '';
        const dateOutputFormat = 'YYYYMMDDTHHmmss[Z]';
        const frequencies = ['YEARLY', 'MONTHLY', 'WEEKLY', 'DAILY'];
        if (!startDateTime || !moment(startDateTime, this.dateInputFormat).isValid()) {
            return undefined;
        }
        if (!endDateTime || !moment(endDateTime, this.dateInputFormat).isValid()) {
            return undefined;
        }
        if (!!frequency && frequency.length > 0 && frequencies.includes(frequency.toUpperCase())) {
            rrule = `FREQ=${frequency.toUpperCase()};`;
            if (!isNaN(parseInt(interval, 10)) && parseInt(interval, 10) > 0) {
                rrule = `${rrule}INTERVAL=${interval};`;
            } else {
                rrule = '';
            }
            if (rrule.length > 0) {
                if (frequency === 'yearly' && !!month && month.length > 0) {
                    const index = this.months.findIndex((m) => m === month);
                    rrule = `${rrule}BYMONTH=${index + 1};`;
                } else if (frequency === 'yearly' && (!month || month.length === 0)) {
                    rrule = '';
                }
            }
            if (rrule.length > 0) {
                if (!!daysOfTheMonth && daysOfTheMonth.length > 0) {
                    rrule = `${rrule}BYMONTHDAY=${daysOfTheMonth.join(',')};`;
                } else if (!!daysOfTheWeek && daysOfTheWeek.length > 0) {
                    rrule = `${rrule}BYDAY=${daysOfTheWeek.map((x) => x.slice(0, 2).toUpperCase()).join(',')};`;
                } else if (
                    !!daysOfTheWeek &&
                    daysOfTheWeek.length === 0 &&
                    !!daysOfTheMonth &&
                    daysOfTheMonth.length === 0 &&
                    frequency.toLowerCase() !== 'daily'
                ) {
                    rrule = '';
                }
            }
            if (rrule.length > 0) {
                if (untilDate !== undefined && moment(untilDate, 'DD. MM. YYYY').isValid()) {
                    const until = moment(untilDate, 'DD. MM. YYYY')
                        .endOf('day')
                        .format(dateOutputFormat);
                    rrule = `${rrule}UNTIL=${until};`;
                } else if (untilNum !== undefined && !isNaN(parseInt(untilNum ?? '', 10))) {
                    const count = untilNum;
                    rrule = `${rrule}COUNT=${count};`;
                }
            }
            const start = moment(startDateTime);
            rrule = `${rrule}BYHOUR=${start.get('hour')};BYMINUTE=${start.get('minute')};`;
            if (rrule.length > 0 && rrule.slice(-1) === ';') {
                rrule = rrule.slice(0, -1);
            }
        }
        return rrule;
    }
    private rrListToRruleString(rrule: Rrule) {
        const events = this.createIcalEventString(rrule);
        if (events?.length === 0) {
            return undefined;
        }
        return events;
    }
    private repetitionChanged(e: Event) {
        if (this.untilSelected === '4') {
            Vue.set(this.rrule, 'untilNum', '999999');
            Vue.set(this.rrule, 'untilDate', null);
            this.untilNum = 999999;
        } else if (this.untilSelected === '3') {
            Vue.set(this.rrule, 'untilDate', null);
            Vue.set(this.rrule, 'untilNum', '1');
            this.untilNum = 1;
        } else if (this.untilSelected === '2') {
            Vue.set(this.rrule, 'untilNum', null);
            this.untilNum = null;
        }
    }
    @Watch('rrule', { deep: true, immediate: true })
    private rruleChanged(newData: any) {
        this.untilNum = this.rrule.untilNum ? parseInt(this.rrule.untilNum, 10) : null;
        this.interval = parseInt(this.rrule.interval, 10);
        const newRrule = { ...this.rrule };
        if (!newData.startDateTime || !newData.endDateTime) {
            return;
        }
        if (newRrule.untilNum && newRrule.untilNum === '999999') {
            this.untilSelected = '4';
            // new_rrule.untilNum = this.untilNum;
            newRrule.untilDate = null;
        } else if (newRrule.untilNum) {
            this.untilSelected = '3';
            // new_rrule.untilNum = this.untilNum;
            newRrule.untilDate = null;
        } else if (newRrule.untilDate) {
            this.untilSelected = '2';
            // newRrule.untilNum = null;
            // newRrule.untilDate = newRrule.untilDate;
        }
        this.rruleString = this.rrListToRruleString(newRrule) ?? null;
        this.$emit('rruleUpdated', this.rruleString);
    }
    @Watch('rrule.frequency')
    private frequencyChanged(newVal: string, oldVal: string) {
        if (newVal !== oldVal) {
            const showable = this.freqToShow[newVal];
            if (!showable?.until) {
                this.rrule.untilDate = null;
                this.rrule.untilNum = null;
                this.untilNum = null;
                this.untilSelected = '1';
            }
            if (!showable?.interval) {
                this.rrule.interval = '1';
            }
            if (!showable?.month) {
                this.rrule.month = '';
            }
            if (!showable?.day_of_month) {
                this.rrule.daysOfTheMonth
                    ? this.rrule.daysOfTheMonth.splice(0, this.rrule.daysOfTheMonth.length)
                    : this.rrule.daysOfTheMonth;
            }
            if (!showable?.day_of_the_week) {
                this.rrule.daysOfTheWeek
                    ? this.rrule.daysOfTheWeek.splice(0, this.rrule.daysOfTheWeek.length)
                    : this.rrule.daysOfTheWeek;
            }
        }
    }
    private mounted() {
        this.frequencyOptions = Object.keys(this.freqToShow);
        const thirty1 = Array.from({ length: 31 }, (v, k) => k + 1);
        const twenty9 = thirty1.slice(0, -2);
        const thirty = thirty1.slice(0, -1);
        this.monthDays = {
            January: thirty1,
            February: twenty9,
            March: thirty1,
            April: thirty,
            May: thirty1,
            June: thirty,
            July: thirty1,
            August: thirty1,
            September: thirty,
            October: thirty1,
            November: thirty,
            December: thirty1,
        };
        this.months = Object.keys(this.monthDays);
        this.rrule.untilNum = this.rrule.untilNum ?? '999999';
        this.untilNum = parseInt(this.rrule.untilNum, 10);
        this.rrule.interval = this.rrule.interval ?? '1';
        this.interval = parseInt(this.rrule.interval, 10);
    }
}
